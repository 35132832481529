<template>
  <dashboard>
    <!-- Porosite -->

    <div class="porositeDiv">
      <div class="container  animate__animated animate__fadeInRight">
        <div class="row">
          <div class="col col-12">
            <div class="incomingTitle">
              <div class="flexdiv">
                <h1>Të nisura</h1>
                <h1 id="number">({{ teNisura }})</h1>
              </div>
              <hr />
            </div>
          </div>

          <!-- Porosite Body-->
          <div
            class="col col-12"
            v-for="(order, index) in myOrder"
            :key="index.order"
          >
            <div class="porositeBody">
              <div class="firstDiv">
                <div class="mainBody">
                  <div class="flexMain">
                    <div class="title">
                      <h1>{{ order.orderName }}</h1>
                      <p>#{{ order.orderCode }}</p>
                    </div>
                  </div>
                </div>

                <div class="progresDiv">
                  <div class="progress-track">
                    <ul id="progressbar">
                      <li
                        class="step0"
                        id="step1"
                        v-bind:class="{
                          active: order.orderStatus >= 1,
                          activeDeclined:
                            order.orderStatus >= 10 && order.orderStatus <= 12,
                          activeWarning: order.orderStatus == 9,
                        }"
                      >
                        <!-- Porosia u vendos -->
                      </li>
                      <li
                        class="step0 text-center"
                        id="step2"
                        v-bind:class="{
                          active: order.orderStatus >= 4,
                          activeDeclined:
                            order.orderStatus >= 10 && order.orderStatus <= 12,
                          activeWarning: order.orderStatus == 9,
                        }"
                      >
                        <!-- Në tranzit -->
                      </li>
                      <li
                        class="step0 text-center"
                        id="step3"
                        v-bind:class="{
                          active: order.orderStatus >= 6,
                          activeDeclined:
                            order.orderStatus >= 10 && order.orderStatus <= 12,
                          activeWarning: order.orderStatus == 9,
                        }"
                      >
                        <!-- <span id="three"> E nisur </span> -->
                      </li>
                      <li
                        class="step0 text-right"
                        id="step4"
                        v-bind:class="{
                          active: order.orderStatus >= 8,
                          activeDeclined:
                            order.orderStatus >= 10 && order.orderStatus <= 12,
                          activeWarning: order.orderStatus == 9,
                        }"
                      >
                        <!-- E dërguar -->
                      </li>
                    </ul>
                  </div>
                  <div class="statusi">
                    <p v-if="order.orderStatus <= 3">
                      <i class="fas fa-check"></i> Porosia e regjistruar
                    </p>
                    <p
                      v-else-if="
                        order.orderStatus >= 3 && order.orderStatus <= 5
                      "
                    >
                      <i class="fas fa-check"></i> Porosia është në bazë
                    </p>
                    <p
                      v-else-if="
                        order.orderStatus >= 6 && order.orderStatus <= 7
                      "
                    >
                      <i class="fas fa-check"></i> Porosia është nisur
                    </p>
                    <p v-else-if="order.orderStatus == 8">
                      <i class="fas fa-check"></i> Porosia është pranuar nga
                      klienti
                    </p>
                    <p v-else-if="order.orderStatus == 9">
                      <i class="fas fa-times"></i> Dërgesa ka dështuar
                    </p>
                    <p v-else-if="order.orderStatus == 10">
                      <i class="fas fa-times"></i> Dërgesa nuk u pranua
                    </p>
                    <p v-else-if="order.orderStatus == 11">
                      <i class="fas fa-times"></i> Porosia është anuluar
                    </p>
                    <p v-else-if="order.orderStatus == 12">
                      <i class="fas fa-undo-alt"></i> Produkti është rikthyer te
                      dërguesi
                    </p>
                  </div>
                </div>
              </div>

              <div class="secondDiv">
                <div class="mainBtn">
                  <div class="image">
                    <img
                      src="../../assets/Images/qrCode-icon.png"
                      @click="openModal(order.orderQR)"
                      alt=""
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      data-backdrop="static"
                      data-keyboard="false"
                    />
                  </div>
                  <router-link :to="'/porosite/' + order.orderCode"
                    ><button>
                      <i class="fas fa-chevron-right"></i> Detajet
                    </button></router-link
                  >
                  <router-link :to="'/vleresimi-sherbimit/' + order.orderCode"
                    ><button>
                      <i class="fas fa-chevron-right"></i> Vlerëso postierin
                    </button></router-link
                  >
                  <a href="#"
                    ><button>
                      <i class="fas fa-chevron-right"></i> Ankohu
                    </button></a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Porosite Body End -->
        </div>
      </div>

      <!-- QR CODE MODAL -->
      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="imageModal">
                <img :src="orderQRimage" alt="" />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="butoni butoni-success"
                data-dismiss="modal"
              >
                <i class="fas fa-share-alt"></i> Shpërndaje
              </button>
              <button
                type="button"
                class="butoni butoni-cancel"
                data-dismiss="modal"
              >
                <i class="fas fa-times"></i> Mbylle
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- QR CODE MODAL -->
    </div>

    <!-- Porosite End -->
  </dashboard>
</template>

<script>
import Dashboard from "../DashHome/dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  data() {
    return {
      teNisura: 1,
      orderQRimage: "",
      myOrder: [
        {
          orderId: 1,
          orderName: "Online Shop",
          orderCode: "NSH3334441133404",
          orderStatus: 3,
          orderStatusName: "E nisur për dërgesë",
          orderQR: require("../../assets/Images/qrCode.png"),
        },
        {
          orderId: 2,
          orderName: "Gaza Construction",
          orderCode: "NSH00034441133404",
          orderStatus: 12,
          orderStatusName: "Të dhënat u pranuan",
          orderQR: require("../../assets/Images/qrCode-icon.png"),
        },
      ],
    };
  },
  methods: {
    openModal: function(vlera) {
      this.orderQRimage = vlera;
    },
  },
};
</script>
